<template>
	<div>
		<b-card>
			<template slot="header">
				<h4 class="mt-2 custom-card-header-text">Requests <span v-if="$route.query.tag">tagged "{{ $route.query.tag | capitalize}}"</span></h4>
				<ul class="list-inline float-right header_table ">
					<li v-if="communities.length>1" class="list-inline-item place-center"><label for="communityFilter" class="custom-card-header-filter label-bottom-0">Community:</label></li>
					<li v-if="communities.length>1" class="list-inline-item">
						<b-form-select v-model="selected" @change="filterByCommunity()" class="custom-card-header-filter" id="communityFilter">
							<option :value="null" selected disabled>Community</option>
							<option value="all">All</option>
							<option v-for="community in communities" :key="community._id" v-bind:value="community._id">{{ community.name}}</option>
						</b-form-select>
					</li>
					<li v-if="circles.length>=1" class="list-inline-item place-center "><label for="circleFilter" class="custom-card-header-filter label-bottom-0 ">Circle:</label></li>
					<li v-if="circles.length>=1" class="list-inline-item">
						<b-form-select v-model="selectedCircle" @change="filterByCircle()" class="custom-card-header-filter" id="circleFilter">
							<option :value="null" selected disabled>Circle</option>
							<option value="all">All</option>
							<option value="none">No Circle</option>
							<option v-for="(circle, idx) in circles" :key="`${idx}$-{circle.short}`" v-bind:value="circle.short" v-if="circle.name && circles.length>=1" >{{ circle.name}}</option>
						</b-form-select>
					</li>
					<li>
						<div class="row-bak d-flex-bak align-items-center-bak">
							<datepicker @changed="dateFilter"
							style="padding-right: 10px;" label="Date Range"></datepicker>
						</div>
					</li>
					<template v-if="$route.query.tag">
						<li class="list-inline-item">
							<b-dropdown class="float-right" v-b-tooltip.hover.bottom title="Additional options" variant="btn btn-dropbox btn-sm tab-focus" right>
								<template slot="button-content">
									<i class="icon-settings"></i>
								</template>
								<b-dropdown-item :to="'/engagement/requests/recent-requests-with-tag?tag=' + $route.query.tag">Recent requests with tag</b-dropdown-item>
								<b-dropdown-item :to="'/engagement/responses/responders-matched-to-tag?tag=' + $route.query.tag">Responders matched to tag</b-dropdown-item>
							</b-dropdown>
						</li>
					</template>
					<li class="list-inline-item">
						<button type="button" aria-label="Export" aria-live="assertive" v-if="exportBtn" @click="exportData()" title="Download request data" class="btn custom-card-header-filter btn-dropbox tab-focus"><i class="icon-cloud-download"></i></button>
						<button type="button" v-if="processBtn" v-b-tooltip.hover.bottom title="Generating report" class="btn custom-card-header-filter btn-dropbox"><i class="fa fa-spinner fa-spin"></i></button>
					</li>
				</ul>
			</template>
			<b-card-body>
				<v-server-table ref="table" :url="serverURL" :columns="columns" :options="options" name="dataTable" id="dataTable" @loaded="onLoaded" class="request-table">
					<span slot="community" slot-scope="props">
                  {{ props.row.community ? props.row.community : 'N/A'}}
                </span>
					<span slot="member" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                  {{ props.row.member}}
                </span>
					<span slot="member" slot-scope="props" v-else-if = "props.row.member">
                  {{ props.row.member}}
                </span>
					<span slot="title" slot-scope="props">
                  {{ props.row.title ? props.row.title : 'N/A'}}
                </span>
					<span slot="date" slot-scope="props" v-if="props.row.date">
                  {{ props.row.date | momentDate}}
                </span>
					<span slot="date" v-else>N/A</span>
					<span slot="status" slot-scope="props" :class="[{'badge': props.row.status}, {'badge-success': setStatus(props.row.status) == 'New'}, {'badge badge-primary': setStatus(props.row.status) == 'Active'}, {'badge-warning': setStatus(props.row.status) == 'In Review' || setStatus(props.row.status) == 'Pending'}, {'badge-primary-light': setStatus(props.row.status) == 'Closed'}, {'badge badge-primary': setStatus(props.row.status) == 'Tag' || setStatus(props.row.status) == 'Matching' || setStatus(props.row.status) == 'Reviewed'}]">
                  {{ props.row.status ? setStatus(props.row.status) : 'N/A'}}
                </span>
				<span slot="favorite" slot-scope="props" v-if="props.row.favorite">
					<button type="button" aria-live="assertive" class="btn custom-card-header-filter btn-transparent btn:focus tab-focus"
					@click="favoriteRequest(props.row.requestId, 'unfavorite')"
					v-b-tooltip.hover.bottom title="favorite" aria-label="favorite">
					<i class="fa fa-star gold-color" aria-label="favorite"></i>
					</button>
                </span>
				<span slot="favorite" slot-scope="props" v-else>
					<button type="button" aria-live="assertive" class="btn custom-card-header-filter btn-transparent btn:focus tab-focus"
					@click="favoriteRequest(props.row.requestId,  'favorite')"
					v-b-tooltip.hover.bottom title="unfavorite" ><i class="fa fa-star-o" aria-label="unfavorite"></i></button>
                </span>
				<span slot="action" slot-scope="props" class="action-width">
					<ul class="list-inline">
						<li class="list-inline-item" v-if="props.row.status != '(8) Rejected'">
							<ul class="list-inline">
								<li class="list-inline-item">
									<b-dropdown class="float-right" v-b-tooltip.hover.bottom title="Show options" aria-label="Show options" aria-live="polite" variant="btn btn-dropbox btn-sm tab-focus" right>
										<template slot="button-content">
											<i class="icon-settings"></i>
										</template>
										<b-dropdown-item  @click="openRequestInNewTab(props.row.requestId)">
											<!-- <span class="count" v-if="props.row.commentCount">({{props.row.commentCount}})</span> -->
											View the request
										</b-dropdown-item>
										<b-dropdown-item  v-if="setStatus(props.row.status) == 'Active' || setStatus(props.row.status) == 'Closed'" @click="$router.push(`/engagement/requests/${props.row.requestId}/story`)">Story mode
										</b-dropdown-item>
										<b-dropdown-item  v-if="setStatus(props.row.status) == 'Active'" @click="openRequestActionUrl(props.row.requestId)">View Action Page
										</b-dropdown-item>
										<b-dropdown-item   v-if="setStatus(props.row.status) !== 'Closed' && setStatus(props.row.status) !== 'Rejected' && setStatus(props.row.status) !== 'Pending'" @click="redirectToCoachPage(props.row.requestId)">Coach and close</b-dropdown-item>
										<b-dropdown-item   v-if="setStatus(props.row.status) == 'Active'" @click="opnAddAdvisorModal(props.row.requestId)">Add Advisor</b-dropdown-item>
										<b-dropdown-item @click="showResponseDialog(props.row.requestId)">Add response</b-dropdown-item>
										<b-dropdown-item v-if="setStatus(props.row.status) == 'Active'" @click="shareRequestModal(props.row.requestId)">Email request
										</b-dropdown-item>
										<b-dropdown-item v-if="setStatus(props.row.status) == 'Active'" @click="referRequest(props.row.requestId,authUser.memberID)">Refer request
										</b-dropdown-item>
										<b-dropdown-item  v-if="setStatus(props.row.status) !== 'Closed' && setStatus(props.row.status) !== 'Rejected'" @click="rejectRequest(props.row.requestId)">Reject the request</b-dropdown-item>
										<b-dropdown-item  v-if="setStatus(props.row.status) == 'Closed' || setStatus(props.row.status) == 'Rejected'" @click="reOpenRequest(props.row.requestId)">Reopen the request</b-dropdown-item>
										<b-dropdown-item v-if="setStatus(props.row.status) == 'In Review'" @click="approveRequest(props.row.requestId, 'approve', 'no')">Approve request</b-dropdown-item>
										<b-dropdown-item  v-if="setStatus(props.row.status) !== 'Closed'" @click="closeRequest(props.row.requestId)">Close the request
										</b-dropdown-item>
									</b-dropdown>
								</li>
								<!-- <li class="list-inline-item" v-if="setStatus(props.row.status) == 'Active' || setStatus(props.row.status) == 'Closed'">
									<button type="button" class="btn custom-card-header-filter btn-dropbox" @click="$router.push(`/engagement/requests/${$route.params.request_id}/story`)" v-b-tooltip.hover.bottom title="Story mode" >
										<i class="cil-heart"></i>
									</button>
								</li>
								<li class="list-inline-item" v-if="setStatus(props.row.status) == 'Active' && (authUser.memberID || authUser.role =='SuperAdmin') || setStatus(props.row.status) == 'In Review'">
									<button type="button" class="btn custom-card-header-filter btn-dropbox notification-btn" @click="openComment()" v-b-tooltip.hover.bottom title="Comment" >
										<span class="count" v-if="commentCount">{{commentCount}}</span>
										<i class="cil-comment-square"></i>
									</button>
								</li>
								<li class="list-inline-item" v-if="setStatus(props.row.status) == 'Active'">
									<button type="button" class="btn custom-card-header-filter btn-dropbox" @click="openRequestActionUrl(ask._id)" v-b-tooltip.hover.bottom title="View Action Page" ><i class="cui-share"></i></button>
								</li>
								<li class="list-inline-item" v-if="setStatus(props.row.status) == 'Active'">
									<button type="button" class="btn custom-card-header-filter btn-dropbox" @click="shareRequestModal(ask._id)" v-b-tooltip.hover.bottom title="Share the request" ><i class="cil-share-alt"></i></button>
								</li>
								<li class="list-inline-item" v-if="setStatus(props.row.status) !== 'Closed'">
									<button type="button" class="btn custom-card-header-filter btn-dropbox" v-b-tooltip.hover.bottom title="Close the request" @click="closeRequest(ask._id)"  ><i class="fa fa-ban"></i></button>
								</li>  -->
							</ul>
						</li>
					</ul>
				</span>
				</v-server-table>
			</b-card-body>
		</b-card>
		<b-modal id="add-advisor-modal" class="add-advisor-modal" size="md" v-model="addAdvisorModal" ok-title="Close" @ok="addAdvisorModal = false" title="Add Advisor">
            <b-card-body>
                <b-form  v-on:submit.prevent="addAdvisorEmailSubmit">
                    <b-form-group
                    label="Email:"
                    label-for="advisorEmail"
                    >
                        <b-form-input
                        type="text"
                        name="advisorEmail"
                        v-model="advisorEmail"
                        @keyup="addAdvisorErrMsg = ''"
                        v-validate="{ required: true, max:100, email: true }"
                        :state="validateState('advisorEmail')"
                        placeholder="Enter email"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback v-if="errors.has('advisorEmail')">
                            {{errors.first('advisorEmail')}}
                        </b-form-invalid-feedback>
                        <span class="red" v-if="addAdvisorErrMsg">
                            {{ addAdvisorErrMsg }}
                        </span>
                    </b-form-group>
                    <b-button type="submit" variant="primary">Add advisor</b-button>
                </b-form>
            </b-card-body>
        </b-modal>
		<b-modal id="share-request-modal" class="share-request-modal" size="md" hide-footer v-model="showShareRequestModal" title="Add email of recipient">
            <b-card-body>
                <b-form  v-on:submit.prevent="submitShareRequest(recipientEmail)">
                    <b-form-group
                    label="Email:"
                    label-for="recipientEmail"
                    >
                        <b-form-input
                        type="text"
                        name="recipientEmail"
                        id="recipientEmail"
                        v-model="recipientEmail"
                        @keyup="addRecipientEmailErrMsg = ''"
                        v-validate="{ required: true, max:100, email: true }"
                        :state="validateState('recipientEmail')"
                        placeholder="Enter email"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback v-if="errors.has('recipientEmail')">
                            {{errors.first('recipientEmail')}}
                        </b-form-invalid-feedback>
                        <span class="red" v-if="addRecipientEmailErrMsg">
                            {{ addRecipientEmailErrMsg }}
                        </span>
                    </b-form-group>
					<div class="">
						<b-button type="submit" variant="primary" :disabled="loadingShareRequest" v-if="loadingShareRequest">Share <i class="fa fa-spinner" aria-hidden="true"></i></b-button>
						<b-button type="submit" variant="primary" v-else>Share</b-button>
						<b-button class="m-5px" variant="secondary" @click="closeShareRequestModal">Close</b-button>
					</div>
                </b-form>
            </b-card-body>
        </b-modal>
		<b-modal id="response-modal" class="response-modal" size="lg" hide-footer  v-model="showResponseModal" title="Add Response">
            <b-card-body>
                <b-form v-on:submit.prevent="addAdvisorResponseSubmit('response')" data-vv-scope="response">
					<b-form-group
                    label="Advisor Email:"
                    label-for="resAdvisorEmail"
                    >
                        <b-form-input
                        type="text"
                        name="resAdvisorEmail"
                        v-model="resAdvisorEmail"
                        @keyup="addResAdvisorErrMsg = ''"
                        v-validate="{ required: true, max:100, email: true }"
                        :state="validateState('response.resAdvisorEmail')"
                        placeholder="Enter advisor email"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback v-if="errors.has('response.resAdvisorEmail')">
                            {{errors.first('response.resAdvisorEmail')}}
                        </b-form-invalid-feedback>
                        <span class="red" v-if="addResAdvisorErrMsg">
                            {{ addResAdvisorErrMsg }}
                        </span>
                    </b-form-group>
					<b-form-group label="Response:" label-for="advisorResponse">
                        <li class="list-inline-item w-100">
                            <b-form-textarea
								id="response-text-comment"
								size="lg"
								name="advisorResponse"
								v-model="advisorResponse"
								@keyup="errorAdvisorResponse = ''"
								v-validate="{ required: true }"
								:state="validateState('response.advisorResponse')"
								placeholder="Response from advisor"
							></b-form-textarea>
							<b-form-invalid-feedback v-if="errors.has('response.advisorResponse')">
								{{errors.first('response.advisorResponse')}}
							</b-form-invalid-feedback>
							<span class="red" v-if="errorAdvisorResponse">
								{{ errorAdvisorResponse }}
							</span>
                        </li>
                    </b-form-group>
					<div class="">
						<b-button class="m-5px tab-focus" type="submit" variant="primary">Submit</b-button>
						<b-button class="m-5px tab-focus" variant="secondary" @click="closeResponseDialog">Close</b-button>
					</div>
                </b-form>
            </b-card-body>
        </b-modal>
	</div>
</template>

<script>
import {shuffleArray} from '@/shared/utils'
import axios from 'axios'
import {exportCSV} from '@/assets/js/common';
import {setRequestStatus} from '@/assets/js/set-status';
var moment = require('moment-timezone');
import VueNotifications from 'vue-notifications';
import datepicker from './components/datePicker';

export default {
	name: 'requests',
	components: {
		datepicker,
	},
	data() {
		return {
			storedParams: "",
			requests: [],
			communities: [],
			ask: {},
			matchedAdvisors: [],
			advisorEmail: '',
			addAdvisorErrMsg: '',
			addAdvisorModal: false,
			circles: [],
			searchQuery: '',
			askCount: 0,
			selected: "all",
			selectedCircle: "all",
			selectedDates: {
				startDate: new Date(0),
				endDate: new Date()
			},
			columns: ['community', 'circleName', 'date', 'member', 'status', 'Sent', 'Opened', 'Clicked', 'Accepted', 'Declined', 'Feedback', 'DonorAlert', 'favorite', 'action'],
			token: '',
			options: {
				headings: {
					circleName: 'Circle',
					Sent: function (h) {return <span data-title="Advisors that were sent the request">S</span>},
					Opened: function (h) {return <span data-title="Advisors that opened the request">O</span>},
					Clicked: function (h) {return <span data-title="Advisors that clicked within the request email">C</span>},
					Accepted: function (h) {return <span data-title="Advisors that answered the request">A</span>},
					Declined: function (h) {return <span data-title="Advisors that declined the request">D</span>},
					Feedback: function (h) {return <span data-title="Feedback left on request and responses.">F</span>},
					DonorAlert: function (h) {return <span data-title="Donor Alerts sent to fundraisers">DA</span>},
					favorite: function (h) {return <span data-title="Favorite requests"><i class="fa fa-star-o" aria-hidden="true"></i></span>}
				},
				sortable: ['circleName', 'date', 'member', 'status', 'Sent', 'Opened', 'Clicked', 'Accepted', 'Declined',  'Feedback', 'DonorAlert','favorite'],
				sortIcon: {base: 'fa', up: 'fa-sort-asc', down: 'fa-sort-desc', is: 'fa-sort'},
				pagination: {
					chunk: 10,
					nav: 'fixed'
				},
				requestFunction: async function (data) {
					this.storedParams = data;
					this.$parent.searchQuery = data.query;
					if (this.$route.query.tag) {
						data.tag = this.$route.query.tag;
					}
					const result = await axios.get(this.url, {headers: {'x-access-token': localStorage.getItem('token')}, params: data}).catch(function (e) {
					}.bind(this));
					return result;
				},
				responseAdapter({data}) {
					this.$parent.askCount = data.askCount;
					return {
						data: data.requestData,
						count: data.askCount
					}
				}
			},
			serverURL: `${process.env.VUE_APP_API_BASE_URL}/dashboard/request`,
			exportBtn: true,
			processBtn: false,
			showResponseModal: false,
			selectedRequestId: "",
			resAdvisorEmail: '',
			addResAdvisorErrMsg: '',
			advisorResponse: "",
			errorAdvisorResponse: "",
			addRecipientEmailErrMsg: '',
            recipientEmail: '',
            showShareRequestModal: false,
			loadingShareRequest: false,
		}
	},
	filters: {
		capitalize: function (value) {
			if (!value) return ''
			value = value.toString()
			return value.charAt(0).toUpperCase() + value.slice(1)
		}
	},
	notifications: {
		showWarnMsg: {
			type: VueNotifications.types.warn,
			message: "No data found for export file"
		},
		showDownloadingMsg: {
			type: VueNotifications.types.success,
			message: "Export request sent.  Data is now downloading."
		},
		showErrorMsg: {
            type: VueNotifications.types.error,
            message: 'This request cannot be shared.'
        },
        showSuccessMsg: {
            type: VueNotifications.types.success,
            message: 'New advisor added successfully.'
        }
	},
	async created() {
		const dict = {
            custom: {
                advisorEmail: {
                    required: 'The email field is required.',
                    email: 'Please enter valid email address.'
                },
				recipientEmail: {
                    required: 'The email field is required.',
                    email: 'Please enter valid email address.'
                },
				resAdvisorEmail: {
                    required: 'The email field is required.',
                    email: 'Please enter valid email address.'
                },
				advisorResponse: {
                    required: 'The response field is required.',
                }
            }
        }
        this.$validator.localize('en', dict);
		this.token = localStorage.getItem('token');
		const user = localStorage.getItem('authUser');
		if (user) {
			this.authUser = JSON.parse(user);
		}
		await this.getCommunities();
	},
	methods: {
		dateFilter(res) {
			console.log("dates [selectedDates]:", res);

			this.selectedDates.startDate =  res.startDate;
			this.selectedDates.endDate =  res.endDate;
			this.onLoaded();
		},
		onLoaded() {
			this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/${this.selected}/requests?circle=${this.selectedCircle}&&startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`;
			this.setActionButtonAnnouncement();
			this.$helpers.setTabindexOnTableCells();
        },
		async getCommunities() {
			const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/sidebar-community`, {headers: {'x-access-token': this.token}})
			this.communities = data;
			if (this.$route.params.community_id) {
				const selectedCommunity = data.filter(community => community._id == this.$route.params.community_id);
				if (selectedCommunity.length <= 1) {
					this.selected = selectedCommunity[0]._id;
				}
			} else {
				if (this.communities.length <= 1) {
					this.selected = this.communities[0]._id;
				}
			}
			this.filterByCommunity();
		},
		filterByCommunity: async function () {
			this.selectedCircle = "all";
			console.log("this.selected : ", this.selected);
			if (this.selected == "all") {
				this.circles = [];
				this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/request`;
			} else {
				this.circles = this.communities.find(c => c._id == this.selected).additionalParams;
				this.circles.sort(function (a, b) {
					if(a.name && b.name) {
						return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
					}
				});
				this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/${this.selected}/requests`;
			}
		},
		filterByCircle: async function () {
			if (this.selectedCircle == "all") {
				this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/${this.selected}/requests`;
			} else {
				this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/${this.selected}/requests?circle=${this.selectedCircle}`;
			}
		},
		setStatus: function (status) {
			return setRequestStatus(status);
		},
		exportData: async function () {
			if (this.askCount > 0) {
				this.showDownloadingMsg();
				this.exportBtn = false;
				this.processBtn = true;
				let url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/request/export/?community_id=${this.selected ? this.selected : ''}&circle=${this.selectedCircle ? this.selectedCircle : ''}&query=${this.searchQuery}&&startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`;
				if (this.$route.query.tag) {
					url = `${process.env.VUE_APP_API_BASE_URL}/dashboard/request/export/?community_id=${this.selected ? this.selected : ''}&circle=${this.selectedCircle ? this.selectedCircle : ''}&tag=${this.$route.query.tag}&query=${this.searchQuery}&&startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`
				}
				let exculdedColumns = ['title', 'requestId', 'memberId', 'commentCount'];
				if (this.authUser && this.authUser.role && this.authUser.role == 'Admin') {
					exculdedColumns.push('tags');
				}
				await this.axios.get(url, {headers: {'x-access-token': this.token}}).then(async response => {
					// console.log('response',response)
					if (response.data.requestData && response.data.requestData.length) {
						// console.log('Request data obtained')
						const requestData = response.data.requestData;
						await exportCSV(requestData.slice(), 'requests', exculdedColumns, ['date']);
						this.exportBtn = true;
						this.processBtn = false;
					} else {
						this.showWarnMsg();
						this.exportBtn = true;
						this.processBtn = false;
					}
				}).catch(err => {
					if (err.response.status === 401) {
						localStorage.removeItem('authUser');
						this.$router.push('/login');
					}
				});
			} else {
				this.showWarnMsg();
			}
		},
		redirectUrls: function (path) {
			window.open((`/engagement/members/${path}`), '_blank');
		},
		openRequestInNewTab(path) {
			window.open((`/engagement/requests/${path}`), '_blank');
		},
		openRequestActionUrl: function (askId) {
            window.open(`${process.env.VUE_APP_WEB_BASE_URL}/request-help/?askid=${askId}&memberid=null&helpaction=yes`, '_blank');
        },
		rejectRequest: async function (askId) {
            await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/request/reject`, {askId: askId},{ headers: {'x-access-token': this.token}}).then(async (response) =>{
            this.$parent.searchQuery = this.$refs.table.storedParams.query;
				if (this.$route.query.tag) {
					this.$refs.table.storedParams.tag = this.$route.query.tag;
				}
				const result = await axios.get(this.serverURL, {headers: {'x-access-token': localStorage.getItem('token')}, params: this.$refs.table.storedParams}).catch(function (e) {}.bind(this));

				this.$refs.table.setData(result);
            });
        },
		reOpenRequest: async function (askId) {
            await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/request/reopen`, {askId: askId},{ headers: {'x-access-token': this.token}}).then(async (response) => {
				this.$parent.searchQuery = this.$refs.table.storedParams.query;
				if (this.$route.query.tag) {
					this.$refs.table.storedParams.tag = this.$route.query.tag;
				}
				const result = await axios.get(this.serverURL, {headers: {'x-access-token': localStorage.getItem('token')}, params: this.$refs.table.storedParams}).catch(function (e) {}.bind(this));

				this.$refs.table.setData(result);
            });
        },
		approveRequest: function(askId, action, status) {
			let params = {
				askId: askId,
				action: action,
				status: status,
				approvedBy: 'dashboard'
			}
			if (this.authUser.memberID) {
				params.adminId = this.authUser.memberID;
			}
			this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/request-approval`, params, {headers: {'x-access-token': this.token}}).then(async response => {
				if (action == 'approve') {
					this.showSuccessMsg({message: 'Thank you for approving the request.'})
				} else if (action == 'reject') {
					this.showSuccessMsg({message: 'Thank you for your response'})
				}
				await this.onLoaded();
				this.$parent.searchQuery = this.$refs.table.storedParams.query;
				if (this.$route.query.tag) {
					this.$refs.table.storedParams.tag = this.$route.query.tag;
				}

				const result = await axios.get(this.serverURL, {headers: {'x-access-token': localStorage.getItem('token')}, params: this.$refs.table.storedParams}).catch(function (e) {}.bind(this));

				this.$refs.table.setData(result);
			}).catch(err => {
				if (err.response.status  === 401) {
					localStorage.removeItem('authUser');
					this.$router.push('/login');
				}
			});
		},
		closeRequest: async function (askId) {
            await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/request/close`, {askId: askId},{ headers: {'x-access-token': this.token}}).then(async (response) =>{

				this.$parent.searchQuery = this.$refs.table.storedParams.query;
				if (this.$route.query.tag) {
					this.$refs.table.storedParams.tag = this.$route.query.tag;
				}

				const result = await axios.get(this.serverURL, {headers: {'x-access-token': localStorage.getItem('token')}, params: this.$refs.table.storedParams}).catch(function (e) {}.bind(this));

				this.$refs.table.setData(result);
            });
        },
		resetResponseDialog: function () {
			this.resAdvisorEmail = this.addResAdvisorErrMsg = '';
			this.advisorResponse = "";
			this.errorAdvisorResponse = "";
		},
		closeResponseDialog: function () {
			this.resetResponseDialog();
			this.showResponseModal = false;
		},
		showResponseDialog: async function (requestId) {
			console.log("requestId: ",requestId);
			this.askRequestData(requestId);
			this.selectedRequestId = requestId;
			this.resetResponseDialog();
			this.showResponseModal = true;
		},
		async addManualResponse(advisor) {
			if (this.ask.status !== '(8) Rejected' && this.ask.status !== '(7) Closed') {
				await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/asks/${this.ask._id}/request/respond`, {advisers: [advisor], insight: this.advisorResponse}, { headers: {'x-access-token': this.token}}).then(ask => {
					this.$emit("reload");
					this.onLoaded();
					// this.showSuccessMsg({message: 'Added manual response successfully'})
					this.closeResponseDialog();
				});
			} else {
				// console.log("this.community :", this.ask.community);
                let closeMessage = this.ask.community.content.closeRequestMessage;
				console.log(closeMessage);
			}
		},
		addAdvisorResponseSubmit: async function (scope) {
			console.log("this.resAdvisorEmail : ", this.resAdvisorEmail);
			this.$validator.validateAll(scope).then(async result => {
				console.log("result :", result);
                if (result) {
					if(this.resAdvisorEmail == "") {
						this.addResAdvisorErrMsg = 'The email field is required'
					} else if(this.advisorResponse == "") {
						this.errorAdvisorResponse = 'Please add the advisor response'
					} else {
						this.showSuccessMsg({message: 'Thank you for submitting a response.'});
						this.showResponseModal = false;
						const matchAdvisor = await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/member/get-match-community-advisor`,
						{
							askId: this.selectedRequestId,
							advisorEmail: this.resAdvisorEmail
						},{
							headers: {'x-access-token': this.token}
						});

						// console.log("result : ", matchAdvisor);
						if(matchAdvisor.data.length > 0) {
							// add response
							this.addManualResponse(matchAdvisor.data[0]._id)
						} else {
							// add new member referral for unknown email.
							const referralMember = await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals/unknown-member`,
							{
								askId: this.selectedRequestId,
								advisorEmail: this.resAdvisorEmail,
								insight: this.advisorResponse
							},{
								headers: {'x-access-token': this.token}
							});

							// console.log("Referral Member : ", referralMember);

							//    // req body for referral response
							const responseObj = {
								email: this.resAdvisorEmail,
								insight: this.advisorResponse,
								action: "Referred Insight",
								help: "request-help",
								helpaction: "yes",
								phoneNo: "",
								calltext: false,
								callMessage: "",
								isTermsChecked: false,

							}

							// add response for referral .
							const referralResponse = await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals/respond/ask/${this.selectedRequestId}`,
							responseObj,{
								headers: {'x-access-token': this.token}
							});

							// console.log("Referral Response : ", referralResponse);
							let authMemberDetails = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/auth-member-details/${this.authUser.memberID}`,{
								headers: {'x-access-token': this.token}
							});
							// console.log("authMemberDetails : ", authMemberDetails);
                           // req body for referral.
							const referralObj = {
								askId: this.selectedRequestId,
								giveId: referralResponse.data.giveId,
								referral: {
									recipient: {
										name: "",
										email: this.resAdvisorEmail
									},
									adviser: {
										name: authMemberDetails.data.displayName ? authMemberDetails.data.displayName: authMemberDetails.data.first + " " + authMemberDetails.data.last,
										email: authMemberDetails.data.emails[0],
									},
									message: this.advisorResponse,
                           		},
								memberId: authMemberDetails.data._id,
								profileUpdateFlag: authMemberDetails.data.profileValidationFlag,
							}

							// add referral data.
							const referral = await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals/store-referral`,
							referralObj,{
								headers: {'x-access-token': this.token}
							});

							// console.log("Referral : ", referral);

							this.$emit("reload");
							this.onLoaded();
							// this.showSuccessMsg({message: 'Added manual referral response successfully'})
							this.closeResponseDialog();
						}
					}
				}
			})
		},
		resetShareRequestModal: function () {
			this.recipientEmail = this.addRecipientEmailErrMsg = '';
		},
		closeShareRequestModal: function () {
			this.resetShareRequestModal();
			this.showShareRequestModal = false;
		},
		shareRequestModal: function (requestId) {
			this.askRequestData(requestId);
            this.recipientEmail = this.addRecipientEmailErrMsg = '';
            if (this.authUser) {
                this.showShareRequestModal = true;
            } else {
                this.showErrorMsg();
            }
        },
		referRequest: function (askId,memberId) {
			console.log("askId: ", askId);
			console.log("memberId: ", memberId);
			window.open(`${process.env.VUE_APP_WEB_BASE_URL}/request-share/?askid=${askId}&memberid=${memberId}&helpaction=no&action=share&location=dashboard`);
        },
        submitShareRequest: function(recipientEmail = '') {
            this.$validator.validateAll().then(async result => {
                if(result) {
					this.showShareRequestModal = false;
					this.loadingShareRequest = true
                    let memberId = this.authUser && this.authUser.memberID;
					let authMemberDetails = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/auth-member-details/${this.authUser.memberID}`,{
						headers: {'x-access-token': this.token}
					});

                    if(memberId != '' && recipientEmail != '' && this.ask && this.ask._id) {
                        const giveId = await this.getGiveId(this.ask._id, memberId);
						// console.log("give Id [submitShareRequest] [request page]:: ", giveId);
						const referralObj = {
							askId: this.ask._id,
							giveId: giveId,
							referral: {
								recipient: {
									name: "",
									email: this.recipientEmail
								},
								adviser: {
									name: authMemberDetails.data.displayName ? authMemberDetails.data.displayName: authMemberDetails.data.first + " " + authMemberDetails.data.last,
									email: authMemberDetails.data.emails[0],
								},
								message: "I'm trying to help out someone from {{ community.name }} and thought of you - are you able to help? If so, just click below to respond directly."
							},
							memberId: memberId,
							profileUpdateFlag: authMemberDetails.data.profileValidationFlag,
						}
						// console.log("this ask [submitShareRequest] [request page]:", this.ask)
						// taken referral message from community content
						if (this.ask && this.ask.community && this.ask.community.content.referralMessage) {
							referralObj.referral.message = this.ask.community.content.referralMessage;
						}

						// replace community name dynamic text from referral message.
						let regexPattern = new RegExp(`\\{\\{\\s?community.name\\s?\\}\\}`, 'gi');
						if (this.ask && this.ask.community && this.ask.community.name) {
							referralObj.referral.message = referralObj.referral.message.replace(regexPattern, this.ask.community.name);
						} else {
							referralObj.referral.message = referralObj.referral.message.replace(regexPattern, '');
						}

						await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals/store-referral`,
							referralObj,{
								headers: {'x-access-token': this.token}
							});
						this.showSuccessMsg({message: 'Thank you. We have successfully shared your request.'})
                    } else {
                        this.showErrorMsg();
                    }
					this.loadingShareRequest = false;
                    this.showShareRequestModal = false;
                }
            })
        },
		getGiveId: async function(askId, memberId) {
			let giveId = "";
            let parameters = {
                advisors: [memberId],
                action: 'Not Available',
                help: 'request-help',
                helpaction: 'no'
            };
            await this.axios
            .post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals/respond/ask/${askId}`, parameters, {headers: {'x-access-token': this.token}})
            .then(response => {
                if (response.data.giveId) {
                    giveId = response.data.giveId;
                }
            });
			return giveId;
        },
		matchedAdvisorPerRequest: async function(requestId) {
			var advList = await this.axios.get(process.env.VUE_APP_API_BASE_URL + `/dashboard/engagement/${requestId}/request-detail`, {headers: {'x-access-token': this.token}});

            if (advList.data.adviserData) {
                this.matchedAdvisors = advList.data.adviserData;
            }
		},
		askRequestData: async function(requestId) {
			await this.axios.get(process.env.VUE_APP_API_BASE_URL + `/asks/${requestId}`, {headers: {'x-access-token': this.token}}).then(request => {
                this.ask = request.data;
            });
		},
		opnAddAdvisorModal(requestId) {
			this.askRequestData(requestId);
            this.advisorEmail = this.addAdvisorErrMsg = '';
            this.addAdvisorModal = true;
        },
		redirectToCoachPage(requestId) {
			this.$router.push({
				path: `/engagement/requests-coach/${requestId}`,
				query: {
					from:'request-page'
				}
			});
		},
		validateState(ref) {
            if (this.errors.has(ref)) {
                return !this.errors.has(ref);
            } else {
                return null;
            }
        },
		addAdvisorEmailSubmit() {
            this.$validator.validateAll().then(async result => {
                if (result) {
                    let memberObj = {
                        email: this.advisorEmail,
                        askId: this.ask._id
                    }
                    await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/ask/add-advisor`, memberObj, {headers: {'x-access-token': this.token}}).then(async response => {
                        if (response.data.success == false && response.data.message) {
                            this.addAdvisorErrMsg = response.data.message
                        } else {
                            this.askRequestData(this.ask._id);
                            this.showSuccessMsg();
                            this.addAdvisorModal = false;
                            this.advisorEmail = this.addAdvisorErrMsg = '';
                        }
                    }).catch(err => {
                        if (err.response.status  === 401) {
                            localStorage.removeItem('authUser');
                            this.$router.push('/login');
                        }
                    });
                }
            })
        },
		async favoriteRequest(requestId, requestType) {
			// console.log("favorite request: ", requestId, "requestType: ", requestType)
			const favorite = requestType == 'favorite' ? true: false;
			await this.axios.post(
				`${process.env.VUE_APP_API_BASE_URL}/dashboard/favorite-request`,
				{askId: requestId, favorite: favorite,requestType: requestType},
				{ headers: {'x-access-token': this.token}}).then(async (response) => {

				this.$parent.searchQuery = this.$refs.table.storedParams.query;
				if (this.$route.query.tag) {
					this.$refs.table.storedParams.tag = this.$route.query.tag;
				}

				const result = await axios.get(this.serverURL, {headers: {'x-access-token': localStorage.getItem('token')}, params: this.$refs.table.storedParams}).catch(function (e) {}.bind(this));

				this.$refs.table.setData(result);
			});
		},
		setActionButtonAnnouncement(){
			const actionButtons = document.querySelectorAll('.dropdown.b-dropdown');
			actionButtons.forEach(button => {
				button.firstElementChild.setAttribute('aria-label', 'Take Action');
			})
		}
	}
}
</script>
